// import "@fontsource/open-sans";
import PropTypes from "prop-types";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../../bulmaConfig.scss";
export const ThemeContext = React.createContext(null);
// import { getScreenWidth, timeoutThrottlerHandler } from "../utils/helpers";

// import Scroll from "../components/Header/scrollback.js";

// export const ScreenWidthContext = React.createContext(0);
// export const FontLoadedContext = React.createContext(false);

// import themeObjectFromYaml from "../theme/theme.yaml";


class Layout extends React.Component {

  render() {
    const { children, pageContext } = this.props;
    // console.log("🚀 ~ file: index.js:23 ~ Layout ~ render ~ children", children.type.propTypes)
    // console.log("🚀 ~ file: index.js:23 ~ Layout ~ render ~ pageContext", pageContext.type)

    // console.log("🚀 ~ file: index.js:23 ~ Layout ~ render ~ children", children)
    // console.log(pageContext)
    return (
      <ThemeContext.Provider >
        <style jsx>{`
                section {
                  position: relative;
                }
                .sectionBackground::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #eef6fc;
                    z-index: -1;
                  }
                }
              `}</style>
        <React.Fragment>
          <Header
            path={this.props.location.pathname}
            theme={""}
            news={!!pageContext.layout}
            pageContext={pageContext}
          />
          <main>{children}</main>
          {/* <Scroll /> */}

          <Footer
            path={this.props.location.pathname}
          />
        </React.Fragment>

      </ThemeContext.Provider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object
};

export default Layout;

//eslint-disable-next-line no-undef
/*
export const postQuery = graphql`
  query LayoutQuery {
    pages: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
      sort: { fields: [fields___prefix], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            menuTitle
          }
        }
      }
    }
    footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
      id
      html
    }
  }
`;

*/
