/* eslint-disable react/no-unknown-property */
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import logo2 from "../../images/jpg/logo-ennomotive.jpg";
import { getCurrentLangKey } from "ptz-i18n";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { FaSearch, FaUserAlt } from "react-icons/fa";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import "./styles.scss";
const translationFile = require("../../pages/locale.json")
// const translationFile = require("./src/pages/locale.json")


class Header extends React.Component {
  state = {
    fixed: false,
    isActive: false
  };

  visibilitySensorChange = val => {
    if (val) {
      this.setState({ fixed: false });
    } else {
      this.setState({ fixed: true });
    }
  };

  toggleNavbar = event => {
    event.target.blur();
    this.setState({ isActive: !this.state.isActive });
  };

  LanguageSelector = ({ classes, lang, location, className, context }) => {

    // Esto busca si la página en la que estás, está dentro del archivo de traducciones, para así mostrar el cambio de idioma o no
    if (translationFile.findIndex(slug => (slug.originalPath.indexOf(location) !== -1) || (slug.translatedPath.indexOf(location) !== -1)) != -1) {

      // Aquí se genera el selector de idioma cambiando la url destino en función de la ruta en la que estás en ese momento
      if (location === "/") {
        return (
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">ENGLISH</a>
            <div className="navbar-dropdown">
              <Link className={`navbar-item ${className}`} onClick={this.toggleNavbar} to={"/es/"}>
                ESPAÑOL
              </Link>
            </div>
          </div>
        );
      } else if (lang === "en") {
        const newPath = (translationFile.find(slug => slug.originalPath === "/es" + location)).translatedPath
        // console.log(newPath)
        return (
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link">ENGLISH</div>
            <div className="navbar-dropdown">
              <Link
                className={`navbar-item ${className}`}
                onClick={this.toggleNavbar}
                to={newPath}
              >
                ESPAÑOL
              </Link>
            </div>
          </div>
        );
      } else if (location === "/es/") {
        return (
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">ESPAÑOL</a>
            <div className="navbar-dropdown">
              <Link className={`navbar-item ${className}`} onClick={this.toggleNavbar} to={"/"}>
                ENGLISH
              </Link>
            </div>
          </div>
        );
      } else {

        const newPath = (translationFile.find(slug => slug.translatedPath === location)).originalPath


        return (
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link">ESPAÑOL</div>
            <div className="navbar-dropdown">
              <Link
                className={`navbar-item ${className}`}
                onClick={this.toggleNavbar}
                to={newPath.substring(3, newPath.length)}
              >
                ENGLISH
              </Link>
            </div>
          </div>
        );
      }
    }

  };

  handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  Menu = ({ lang }) => {
    if (lang === "en") {
      return (
        <>
          <CookieConsent
            location="bottom"
            buttonText="I Accept"
            cookieName="cookiesEN"
            style={{ background: "#FFF", border: "1px grey solid", justifyContent: "Center" }}
            buttonStyle={{
              color: "#FFF",
              fontSize: "13px",
              background: "#0D76A3",
              marginTop: "25px"
            }}
            enableDeclineButton
            declineButtonText="I Decline"
            declineButtonStyle={{
              color: "#FFF",
              fontSize: "13px",
              background: "#7d7f7d",
              marginTop: "25px"
            }}
            expires={150}
            onDecline={this.handleDeclineCookie}
          >
            <p style={{ color: "#000", justifyContent: "Center" }}>
              We use our own and third-party cookies to obtain statistical
              data on the navigation of our users and improve our services.
              If you accept or continue browsing, we consider that you accept their use.
              You can change the settings or get more information <Link to="/privacy-policy/">here</Link>
            </p>
          </CookieConsent>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">ABOUT US</div>
            <div className="navbar-dropdown">
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/press-news/">
                {" "}
                Press and News
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/ennomotive-team/">
                Meet Us
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/open-innovation/">
                Open Innovation
              </Link>
              <OutboundLink
                className="navbar-item"
                onClick={this.toggleNavbar}
                href="http://www.oxyvitaventilator.com/indexEN.html"
                target="_blank"
              >
                OxyVita Ventilator
              </OutboundLink>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/innovation-best-practices/"
              >
                Innovation Best Practices
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/contact-us/">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">FOR COMPANIES</div>
            <div className="navbar-dropdown">
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/innovation-consulting/"
              >
                Innovation Consulting
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/open-innovation-challenges/"
              >
                Open Innovation Challenges
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/open-innovation-ecosystems/"
              >
                Innovation ecosystems / SAAS platform
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/contact-us/">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">FOR SOLVERS</div>
            <div className="navbar-dropdown">
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/startup-calls/">
                For Startups
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/technology-expert-networks/"
              >
                For Experts
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/jobs-for-engineers/"
              >
                Jobs
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} target="_blank" to="https://app.ennomotive.com/user/login">
                Sign up
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">ECOSYSTEMS</div>
            <div className="navbar-dropdown">
              {/* <Link className="navbar-item" onClick={this.toggleNavbar} to="/technology-scouting/"> */}
              {/* Technology scouting */}
              {/* </Link> */}
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/engineering-challenges/"
              >
                Engineering Ecosystem
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/social-solver-ecosystem/"
              >
                Social challenges Ecosystem
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/technology-blog/">
                Technology Blog
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">CHALLENGES</div>
            <div className="navbar-dropdown">
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/how-does-it-work/">
                How does it work?
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/recent-challenges/">
                Recent Challenges
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/ennomotive-ranking/"
              >
                Ennomotive Ranking
              </Link>
              {/* <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/social-impact-proyect/"
              >
                Social Impact Projects
              </Link> */}
            </div>
          </div>
          <Link className="navbar-item" to="/search/">
            <FaSearch className="has-text-primary" />
          </Link>
        </>
      );
    } else {
      return (
        <>
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Aceptar"
            declineButtonText="Rechazar"
            cookieName="cookiesES"
            style={{ background: "#FFF", border: "1px grey solid", justifyContent: "Center" }}
            buttonStyle={{
              color: "#FFF",
              fontSize: "13px",
              background: "#0D76A3",
              marginTop: "25px"
            }}
            declineButtonStyle={{
              color: "#FFF",
              fontSize: "13px",
              background: "#7d7f7d",
              marginTop: "25px"
            }}
            expires={150}
            onDecline={this.handleDeclineCookie}
          >
            <p style={{ color: "#000", justifyContent: "Center" }}>
              Utilizamos cookies propias y de terceros para obtener datos estadísticos
              de la navegación de nuestros usuarios y mejorar nuestros servicios.
              Si acepta o continúa navegando, consideramos que acepta su uso. Puede cambiar la configuración
              u obtener más información <Link to="/es/politica-de-privacidad/"> aquí</Link>
            </p>

          </CookieConsent >
          <div className="navbar-item has-dropdown is-hoverable ">
            <div className="navbar-link pl-0">SOBRE NOSOTROS</div>
            <div className="navbar-dropdown">
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/es/prensa-noticias/">
                Prensa y Noticias
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/es/equipo-ennomotive/">
                Conócenos
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/innovacion-abierta/"
              >
                Innovación Abierta
              </Link>
              <OutboundLink
                className="navbar-item"
                onClick={this.toggleNavbar}
                navbar-item
                href="http://www.oxyvitaventilator.com/index.html"
                target="blank/"
              >
                Respirador OxyVita
              </OutboundLink>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/mejores-practicas-innovacion/"
              >
                Mejores Prácticas en Innovación
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/es/contacto/">
                Contacto
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">PARA EMPRESAS</div>
            <div className="navbar-dropdown">
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/consultoria-de-innovacion/"
              >
                Consultoría de Innovación
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/desafios-innovacion-abierta/"
              >
                Desafíos de Innovacion Abierta
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/ecosistemas-innovacion-abierta/"
              >
                Ecosistemas de innovación / plataforma SAAS
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/es/contacto/">
                Contacto
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">PARA SOLVERS</div>
            <div className="navbar-dropdown">
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/es/startup-community/">
                Para Startups
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/proyectos-de-id-expertos/"
              >
                Para Expertos
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/ofertas-empleo-ingenieros/"
              >
                Ofertas de empleo
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} target="_blank" to="https://app.ennomotive.com/user/login">
                Regístrate
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">ECOSISTEMAS</div>
            <div className="navbar-dropdown">
              {/* <Link className="navbar-item"onClick={this.toggleNavbar}to="/es/scouting-tecnologico/"> */}
              {/* Scouting tecnologico */}
              {/* </Link> */}
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/desafios-ingenieria/"
              >
                Ecosistema de Ingeniería
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/ecosistema-social-solver/"
              >
                Ecosistema Desafios Sociales
              </Link>
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/es/blog-tecnologia/">
                Blog de Tecnología
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link pl-0">DESAFÍOS</div>
            <div className="navbar-dropdown">
              <Link className="navbar-item" onClick={this.toggleNavbar} to="/es/como-funciona/">
                ¿Cómo funcionan?
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/desafios-recientes/"
              >
                Desafíos Recientes
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/ennomotive-ranking-ingenieros/"
              >
                Ránking de ennomotive
              </Link>
              <Link
                className="navbar-item"
                onClick={this.toggleNavbar}
                to="/es/cooperacion-al-desarrollo/"
              >
                Cooperación al desarrollo
              </Link>
            </div>
          </div>
          <Link className="navbar-item" to="/es/buscar/">
            <FaSearch className="has-text-primary" />
          </Link>
        </>
      );
    }
  };

  getHeaderSize = () => {
    const fixed = this.state.fixed ? "fixed" : "";
    const homepage = this.props.path === "/" || this.props.path === "/es/" ? "homepage" : "";

    return `${fixed} ${homepage}`;
  };

  render() {
    const { path, news, pageContext } = this.props;
    const lang = getCurrentLangKey(["en", "es"], "en", path);

    return (
      <React.Fragment>
        <nav
          id="scroll-up"
          className="navbar is-spaced "
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <a href={lang === "en" ? "/" : "/es/"}>
              <img src={logo2} style={{ height: "60px", width: "84px" }} alt="ENNOMOTIVE LOGO" />
            </a>
            <a
              onClick={() => {
                this.setState({ isActive: !this.state.isActive });
              }}
              role="button"
              className={`navbar-burger burger ${this.state.isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div
            id="navbarBasicExample"
            className={`navbar-menu ${this.state.isActive ? "is-active" : ""}`}
          >
            <div className="navbar-end is-size-6">
              <div className="navbar-item  m-0 p-3">
                <this.Menu lang={lang}></this.Menu>
              </div>
              <div className="navbar-item m-0 p-3">
                {!news && !path.includes("/challenge/") && !path.includes("/es/webinars") && (
                  <this.LanguageSelector
                    className="mr-4 ml-2"
                    location={path}
                    context={pageContext}
                    lang={lang}
                  />
                )}
              </div>
              <div className="navbar-item m-0 p-3">
                <div className="navbar-item has-dropdown is-hoverable ">
                  <div className="navbar-link pl-0">
                    <FaUserAlt />
                  </div>
                  <div className="navbar-dropdown p-4" style={{ left: "-150px", width: "200px" }}>
                    <a
                      href="https://app.ennomotive.com/user/register"
                      onClick={this.toggleNavbar}
                      className="button is-primary is-fullwidth mb-2"
                      target="blank/"
                    >
                      <strong>Sign up</strong>
                    </a>
                    <a
                      href="https://app.ennomotive.com/user/login"
                      onClick={this.toggleNavbar}
                      className="button is-light is-fullwidth"
                      target="blank/"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* --- STYLES --- */}
        <style jsx>{`
          .header {
            align-items: center;
            justify-content: left;
            display: flex;
            position: relative;
            top: 0;
            width: 100%;
            align-items: center;

            :global(a.logoType) {
              align-items: center;
              display: flex;
              flex-direction: "column";

              .logo {
                flex-shrink: 0;
              }
            }

            &.homepage {
              position: absolute;
              background-color: transparent;
            }
          }

          h1 {
          }


          .logo {
            display: none;
            height: 44px;
            overflow: hidden;
            width: 44px;
            transition: all 0.5s;

            .homepage & {
              height: 60px;
              width: 60px;
            }

            img {
              width: 100%;
            }
          }

          .sensor {
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 1;
            left: 0;
            right: 0;
            height: 1px;
          }

          @from-width tablet {
            .header {

              &.homepage {
              }
            }
          }

          @below desktop {
            .header.homepage {
              .logo {
                border: none;
              }

              :global(a.logoType),
              h1 {
              }
              h2 {
              }
            }
          }

          @from-width desktop {
            .header {
              align-items: center;
              display: flex;
              position: absolute;
              top: 0;
              width: 100%;
              justify-content: space-between;
              transition: padding 0.5s;

              &.fixed {
                left: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 1;

                h1 {
                }
              }

              &.homepage:not(.fixed) {
                :global(a.logoType),
                h1 {
                }
                h2 {
                }
              }
            }

            .header :global(a.logoType) {
              text-align: left;
              flex-direction: row;
              flex-shrink: 0;
              width: auto;
            }

            .logo {

              .fixed & {
                height: 36px;
                width: 36px;
              }

              .header.homepage:not(.fixed) & {
                border: none;
              }
            }

            h2 {
              animation-name: h2Entry;
            }

            @keyframes h2Entry {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}
// console.log("🚀 ~ file: index.js:643 ~ Header ~ location", location)
// console.log("🚀 ~ file: index.js:643 ~ Header ~ location", location)

Header.propTypes = {
  path: PropTypes.string.isRequired,
  news: PropTypes.bool,
  pageContext: PropTypes.object
};

export default Header;
