import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import ministerio from "../../images/png/ministerio-economia-y-competitividad.png";
import icex from "../../images/png/ICEX.png";
import ue from "../../images/png/UE.png";
import SealOfExcelence from "../../images/jpg/SealOfExcelence.jpg";
import startupchile from "../../images/png/startupchile.png";
import innova from "../../images/jpg/innova.jpg";
import { getCurrentLangKey } from "ptz-i18n";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { FaTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import "./styles.scss";
const Footer = (props) => {
  const { path } = props;
  const lang = getCurrentLangKey(["en", "es"], "en", path);
  // const lang = "en"
  if (lang === "es") {
    return (
      <React.Fragment>
        <>
          <footer className="footer mt-5">
            <div className="content ">
              <div
                style={{ rowGap: "30px" }}
                className="is-flex is-flex-direction-row is-justify-content-space-evenly is-flex-wrap-wrap is-align-items-center"
              >
                <div>
                  <p className="alignRight">
                    2024 ENNOMOTIVE SL |&nbsp;
                    <strong>
                      <Link to="/es/politica-de-privacidad/">
                        Información Legal
                      </Link>
                    </strong>
                    &nbsp;| Si tienes alguna pregunta acerca de nuestra
                    plataforma de innovación abierta,&nbsp;
                    <strong>
                      <Link to="/es/contacto/">
                        ponte en contacto con nosotros
                      </Link>
                    </strong>
                    .
                  </p>
                </div>
                <div
                  style={{ gap: "20px", boxSizing: "content-box" }}
                  className="is-flex is-flex-direction-row is-justify-content-space-evenly is-flex-wrap-wrap ml-4"
                >
                  <OutboundLink
                    aria-label="Twitter"
                    href="https://twitter.com/ennomotive"
                    target="blank/"
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        fontSize: "20px",
                      }}
                      className="p-2 is-flex is-justify-content-center is-align-items-center button is-primary"
                    >
                      <FaTwitter />
                    </div>
                  </OutboundLink>
                  <OutboundLink
                    aria-label="Linkedin"
                    href="https://www.linkedin.com/company/ennomotive/"
                    target="blank/"
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        fontSize: "20px",
                      }}
                      className="p-2 is-flex is-justify-content-center is-align-items-center button is-primary"
                    >
                      <FaLinkedinIn />
                    </div>
                  </OutboundLink>
                  <OutboundLink
                    aria-label="Youtube"
                    href="https://www.youtube.com/c/Ennomotive/videos"
                    target="blank/"
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        fontSize: "25px",
                      }}
                      className="p-2 is-flex is-justify-content-center is-align-items-center button is-primary"
                    >
                      <FaYoutube />
                    </div>
                  </OutboundLink>
                </div>
              </div>

              <div className="columns is-desktop mt-6">
                <div
                  style={{
                    height: "100% !important",
                    borderRight: "2px solid #eee",
                  }}
                  className="column alignLeft p-5"
                >
                  <aside className="menu">
                    <p className="menu-label is-size-6">
                      <strong>SOBRE NOSOTROS</strong>
                    </p>
                    <ul style={{ listStyle: "none" }} className="menu-list m-0">
                      <li>
                        <Link to="/es/prensa-noticias/">Prensa y Noticias</Link>
                      </li>
                      <li>
                        <Link to="/es/equipo-ennomotive/">Conócenos</Link>
                      </li>
                      <li>
                        <Link to="/es/innovacion-abierta/">
                          Innovación Abierta
                        </Link>
                      </li>
                      <li>
                        <Link
                          navbar-item
                          to="http://www.oxyvitaventilator.com/index.html"
                          target="blank/"
                        >
                          Respirador OxyVita
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/mejores-practicas-innovacion/">
                          Mejores Prácticas en Innovación
                        </Link>
                      </li>
                      <li>
                        <strong>
                          <Link to="/es/contacto/">Contacto</Link>
                        </strong>
                      </li>
                    </ul>
                  </aside>
                </div>
                <div
                  style={{
                    height: "100% !important",
                    borderRight: "2px solid #eee",
                  }}
                  className="column alignLeft p-5 "
                >
                  <aside className="menu">
                    <p className="menu-label is-size-6">
                      <strong>PARA EMPRESAS</strong>
                    </p>
                    <ul style={{ listStyle: "none" }} className="menu-list m-0">
                      <li>
                        <Link to="/es/consultoria-de-innovacion/">
                          Consultoría de Innovación
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/desafios-innovacion-abierta/">
                          Desafíos de Innovacion Abierta
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/ecosistemas-innovacion-abierta/">
                          Ecosistemas de innovación / plataforma SAAS
                        </Link>
                      </li>
                      <li>
                        <strong>
                          <Link to="/es/contacto/">Contacto</Link>
                        </strong>
                      </li>
                    </ul>
                  </aside>
                </div>
                <div
                  style={{
                    height: "100% !important",
                    borderRight: "2px solid #eee",
                  }}
                  className="column alignLeft p-5"
                >
                  <aside className="menu">
                    <p className="menu-label is-size-6">
                      <strong>PARA SOLVERS</strong>
                    </p>
                    <ul style={{ listStyle: "none" }} className="menu-list m-0">
                      <li>
                        <Link to="/es/startup-community/">Para Startups</Link>
                      </li>
                      <li>
                        <Link to="/es/proyectos-de-id-expertos/">
                          Para Expertos
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/ofertas-empleo-ingenieros/">
                          Ofertas de empleo
                        </Link>
                      </li>
                      <li>
                        <a target="_blank" href="https://app.ennomotive.com/user/login">
                          <b>Regístrate</b>
                        </a>
                      </li>
                    </ul>
                  </aside>
                </div>
                <div
                  style={{
                    height: "100% !important",
                    borderRight: "2px solid #eee",
                  }}
                  className="column alignLeft p-5"
                >
                  <aside className="menu">
                    <p className="menu-label is-size-6">
                      <strong>ECOSISTEMAS</strong>
                    </p>
                    <ul style={{ listStyle: "none" }} className="menu-list m-0">
                      <li>
                        <Link to="/es/desafios-ingenieria/">
                          Ecosistema de Ingeniería
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/ecosistema-social-solver/">
                          Ecosistema Desafios Sociales
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/blog-tecnologia/">
                          Blog de Tecnología
                        </Link>
                      </li>
                    </ul>
                  </aside>
                </div>
                <div className="column alignLeft p-5">
                  <aside className="menu">
                    <p className="menu-label is-size-6">
                      <strong>DESAFÍOS</strong>
                    </p>
                    <ul style={{ listStyle: "none" }} className="menu-list m-0">
                      <li>
                        <Link to="/es/como-funciona/">¿Cómo funcionan?</Link>
                      </li>
                      <li>
                        <Link to="/es/desafios-recientes/">
                          Desafíos Recientes
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/ennomotive-ranking-ingenieros/">
                          Ránking de ennomotive
                        </Link>
                      </li>
                      <li>
                        <Link to="/es/cooperacion-al-desarrollo/">
                          Cooperación al desarrollo
                        </Link>
                      </li>
                    </ul>
                  </aside>
                </div>
              </div>
              <div
                style={{ gap: "40px" }}
                className="is-flex is-justify-content-center is-flex-direction-row is-flex-wrap-wrap is-align-items-center mt-6"
              >
                {/* <LazyLoadComponent> */}
                <img
                  style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                  src={ministerio}
                  alt="ministerio-economia-y-competitividad"
                />
                {/* </LazyLoadComponent> */}
                {/* <LazyLoadComponent> */}
                <img
                  style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                  src={icex}
                  alt="españa-importacion-e-inversiones"
                />
                {/* </LazyLoadComponent> */}
                {/* <LazyLoadComponent> */}
                <img
                  style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                  src={ue}
                  alt="fondo-europeo-de-desarrollo-regional"
                />
                {/* </LazyLoadComponent> */}
                {/* <LazyLoadComponent> */}
                <img
                  style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                  src={SealOfExcelence}
                  alt="seal-of-excelence"
                />
                {/* </LazyLoadComponent> */}
                {/* <LazyLoadComponent> */}
                <img
                  style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                  src={startupchile}
                  alt="startup-chile"
                />
                {/* </LazyLoadComponent> */}
                <img
                  style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                  src={innova}
                  alt="innova"
                ></img>
              </div>
            </div>
          </footer>
          <style jsx>{`
            @media screen and (max-width: 1023px) {
              .alignLeft {
                border: 0 !important;
                border-bottom: 2px solid #eee !important;
              }
            }
          `}</style>
        </>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <>
        <footer className="footer mt-5">
          <div className="content ">
            <div
              style={{ rowGap: "30px" }}
              className="is-flex is-flex-direction-row is-justify-content-space-evenly is-flex-wrap-wrap is-align-items-center"
            >
              <div>
                <p className="alignRight">
                  2024 ENNOMOTIVE SL |&nbsp;
                  <strong>
                    <Link to="/privacy-policy/">Legal Info</Link>
                  </strong>
                  &nbsp;| If you have any questions about our open innovation
                  platform, please&nbsp;
                  <strong>
                    <Link to="/contact-us/">contact us</Link>
                  </strong>
                  .
                </p>
              </div>
              <div
                style={{ gap: "20px", boxSizing: "content-box" }}
                className="is-flex is-flex-direction-row is-justify-content-space-evenly is-flex-wrap-wrap ml-4"
              >
                <OutboundLink
                  aria-label="Twitter"
                  href="https://twitter.com/ennomotive"
                  target="blank/"
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      fontSize: "20px",
                    }}
                    className="p-2 is-flex is-justify-content-center is-align-items-center button is-primary"
                  >
                    <FaTwitter />
                  </div>
                </OutboundLink>
                <OutboundLink
                  aria-label="Linkedin"
                  href="https://www.linkedin.com/company/ennomotive/"
                  target="blank/"
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      fontSize: "20px",
                    }}
                    className="p-2 is-flex is-justify-content-center is-align-items-center button is-primary"
                  >
                    <FaLinkedinIn />
                  </div>
                </OutboundLink>
                <OutboundLink
                  aria-label="Youtube"
                  href="https://www.youtube.com/c/Ennomotive/videos"
                  target="blank/"
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      fontSize: "25px",
                    }}
                    className="p-2 is-flex is-justify-content-center is-align-items-center button is-primary"
                  >
                    <FaYoutube />
                  </div>
                </OutboundLink>
              </div>
            </div>

            <div className="columns is-desktop mt-6">
              <div
                style={{
                  height: "100% !important",
                  borderRight: "2px solid #eee",
                }}
                className="column alignLeft p-5"
              >
                <aside className="menu">
                  <p className="menu-label is-size-6">
                    <strong>ABOUT US</strong>
                  </p>
                  <ul style={{ listStyle: "none" }} className="menu-list m-0">
                    <li>
                      <Link to="/press-news/">Press and News</Link>
                    </li>
                    <li>
                      <Link to="/ennomotive-team/">Our Team</Link>
                    </li>
                    <li>
                      <Link to="/open-innovation/">Open Innovation</Link>
                    </li>
                    <li>
                      <OutboundLink
                        href="http://www.oxyvitaventilator.com/indexEN.html"
                        target="blank/"
                      >
                        Oxyvita Ventilator
                      </OutboundLink>
                    </li>
                    <li>
                      <Link to="/innovation-best-practices/">
                        Innovation Best Practices
                      </Link>
                    </li>
                    <li>
                      <strong>
                        <Link to="/contact-us/">Contact Us</Link>
                      </strong>
                    </li>
                  </ul>
                </aside>
              </div>
              <div
                style={{
                  height: "100% !important",
                  borderRight: "2px solid #eee",
                }}
                className="column alignLeft p-5 "
              >
                <aside className="menu">
                  <p className="menu-label is-size-6">
                    <strong>FOR COMPANIES</strong>
                  </p>
                  <ul style={{ listStyle: "none" }} className="menu-list m-0">
                    <li>
                      <Link to="/innovation-consulting/">
                        Innovation Consulting
                      </Link>
                    </li>
                    <li>
                      <Link to="/open-innovation-challenges/">
                        Open Innovation Challenges
                      </Link>
                    </li>
                    <li>
                      <Link to="/open-innovation-ecosystems/">
                        Innovation ecosystems / SAAS platform
                      </Link>
                    </li>
                    <li>
                      <strong>
                        <Link to="/contact-us/">Contact Us</Link>
                      </strong>
                    </li>
                  </ul>
                </aside>
              </div>
              <div
                style={{
                  height: "100% !important",
                  borderRight: "2px solid #eee",
                }}
                className="column alignLeft p-5"
              >
                <aside className="menu">
                  <p className="menu-label is-size-6">
                    <strong>FOR SOLVERS</strong>
                  </p>
                  <ul style={{ listStyle: "none" }} className="menu-list m-0">
                    <li>
                      <Link to="/startup-calls/">For Startups</Link>
                    </li>
                    <li>
                      <Link to="/technology-expert-networks/">For Experts</Link>
                    </li>
                    <li>
                      <Link to="/jobs-for-engineers/">Jobs</Link>
                    </li>
                    <li>
                      <a target="_blank" href="https://app.ennomotive.com/user/login">
                        <b>Sign up</b>
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div
                style={{
                  height: "100% !important",
                  borderRight: "2px solid #eee",
                }}
                className="column alignLeft p-5"
              >
                <aside className="menu">
                  <p className="menu-label is-size-6">
                    <strong>ECOSYSTEMS</strong>
                  </p>
                  <ul style={{ listStyle: "none" }} className="menu-list m-0">
                    <li>
                      <Link to="/engineering-challenges/">
                        Engineering Ecosystem
                      </Link>
                    </li>
                    <li>
                      <Link to="/social-solver-ecosystem/">
                        Social challenges Ecosystem
                      </Link>
                    </li>
                    <li>
                      <Link to="/technology-blog/">Technology Blog</Link>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className="column alignLeft p-5">
                <aside className="menu">
                  <p className="menu-label is-size-6">
                    <strong>CHALLENGES</strong>
                  </p>
                  <ul style={{ listStyle: "none" }} className="menu-list m-0">
                    <li>
                      <Link to="/how-does-it-work/">How does it work?</Link>
                    </li>
                    <li>
                      <Link to="/recent-challenges/">Recent Challenges</Link>
                    </li>
                    <li>
                      <Link to="/ennomotive-ranking/">Ennomotive Ranking</Link>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
            <div
              style={{ gap: "40px" }}
              className="is-flex is-justify-content-center is-flex-direction-row is-flex-wrap-wrap is-align-items-center mt-6"
            >
              {/* <LazyLoadComponent> */}
              <img
                style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                src={ministerio}
                alt="ministerio-economia-y-competitividad"
              />
              {/* </LazyLoadComponent> */}
              {/* <LazyLoadComponent> */}
              <img
                style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                src={icex}
                alt="españa-importacion-e-inversiones"
              />
              {/* </LazyLoadComponent> */}
              {/* <LazyLoadComponent> */}
              <img
                style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                src={ue}
                alt="fondo-europeo-de-desarrollo-regional"
              />
              {/* </LazyLoadComponent> */}
              {/* <LazyLoadComponent> */}
              <img
                style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                src={SealOfExcelence}
                alt="seal-of-excelence"
              />
              {/* </LazyLoadComponent> */}
              {/* <LazyLoadComponent> */}
              <img
                style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                src={startupchile}
                alt="startup-chile"
              />
              {/* </LazyLoadComponent> */}
              <img
                style={{ height: "auto", maxWidth: "150px", width: '100%' }}
                src={innova}
                alt="innova"
              ></img>
            </div>
          </div>
        </footer>
        <style jsx>{`
          @media screen and (max-width: 1023px) {
            .alignLeft {
              border: 0 !important;
              border-bottom: 2px solid #eee !important;
            }
          }
        `}</style>
      </>
    </React.Fragment>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
  path: PropTypes.string.isRequired,
};

export default Footer;
