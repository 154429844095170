exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenge-rate-index-en-js": () => import("./../../../src/pages/challenge-rate/index.en.js" /* webpackChunkName: "component---src-pages-challenge-rate-index-en-js" */),
  "component---src-pages-challenge-rate-index-es-js": () => import("./../../../src/pages/challenge-rate/index.es.js" /* webpackChunkName: "component---src-pages-challenge-rate-index-es-js" */),
  "component---src-pages-contact-us-index-en-js": () => import("./../../../src/pages/contact-us/index.en.js" /* webpackChunkName: "component---src-pages-contact-us-index-en-js" */),
  "component---src-pages-contact-us-index-es-js": () => import("./../../../src/pages/contact-us/index.es.js" /* webpackChunkName: "component---src-pages-contact-us-index-es-js" */),
  "component---src-pages-engineering-challenges-index-en-js": () => import("./../../../src/pages/engineering-challenges/index.en.js" /* webpackChunkName: "component---src-pages-engineering-challenges-index-en-js" */),
  "component---src-pages-engineering-challenges-index-es-js": () => import("./../../../src/pages/engineering-challenges/index.es.js" /* webpackChunkName: "component---src-pages-engineering-challenges-index-es-js" */),
  "component---src-pages-ennomotive-ranking-index-en-js": () => import("./../../../src/pages/ennomotive-ranking/index.en.js" /* webpackChunkName: "component---src-pages-ennomotive-ranking-index-en-js" */),
  "component---src-pages-ennomotive-ranking-index-es-js": () => import("./../../../src/pages/ennomotive-ranking/index.es.js" /* webpackChunkName: "component---src-pages-ennomotive-ranking-index-es-js" */),
  "component---src-pages-ennomotive-team-index-en-js": () => import("./../../../src/pages/ennomotive-team/index.en.js" /* webpackChunkName: "component---src-pages-ennomotive-team-index-en-js" */),
  "component---src-pages-ennomotive-team-index-es-js": () => import("./../../../src/pages/ennomotive-team/index.es.js" /* webpackChunkName: "component---src-pages-ennomotive-team-index-es-js" */),
  "component---src-pages-how-does-it-work-index-en-js": () => import("./../../../src/pages/how-does-it-work/index.en.js" /* webpackChunkName: "component---src-pages-how-does-it-work-index-en-js" */),
  "component---src-pages-how-does-it-work-index-es-js": () => import("./../../../src/pages/how-does-it-work/index.es.js" /* webpackChunkName: "component---src-pages-how-does-it-work-index-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-innovation-best-practices-index-en-js": () => import("./../../../src/pages/innovation-best-practices/index.en.js" /* webpackChunkName: "component---src-pages-innovation-best-practices-index-en-js" */),
  "component---src-pages-innovation-best-practices-index-es-js": () => import("./../../../src/pages/innovation-best-practices/index.es.js" /* webpackChunkName: "component---src-pages-innovation-best-practices-index-es-js" */),
  "component---src-pages-innovation-consulting-index-en-js": () => import("./../../../src/pages/innovation-consulting/index.en.js" /* webpackChunkName: "component---src-pages-innovation-consulting-index-en-js" */),
  "component---src-pages-innovation-consulting-index-es-js": () => import("./../../../src/pages/innovation-consulting/index.es.js" /* webpackChunkName: "component---src-pages-innovation-consulting-index-es-js" */),
  "component---src-pages-jobs-for-engineers-index-en-js": () => import("./../../../src/pages/jobs-for-engineers/index.en.js" /* webpackChunkName: "component---src-pages-jobs-for-engineers-index-en-js" */),
  "component---src-pages-jobs-for-engineers-index-es-js": () => import("./../../../src/pages/jobs-for-engineers/index.es.js" /* webpackChunkName: "component---src-pages-jobs-for-engineers-index-es-js" */),
  "component---src-pages-open-innovation-challenges-index-en-js": () => import("./../../../src/pages/open-innovation-challenges/index.en.js" /* webpackChunkName: "component---src-pages-open-innovation-challenges-index-en-js" */),
  "component---src-pages-open-innovation-challenges-index-es-js": () => import("./../../../src/pages/open-innovation-challenges/index.es.js" /* webpackChunkName: "component---src-pages-open-innovation-challenges-index-es-js" */),
  "component---src-pages-open-innovation-ecosystems-index-en-js": () => import("./../../../src/pages/open-innovation-ecosystems/index.en.js" /* webpackChunkName: "component---src-pages-open-innovation-ecosystems-index-en-js" */),
  "component---src-pages-open-innovation-ecosystems-index-es-js": () => import("./../../../src/pages/open-innovation-ecosystems/index.es.js" /* webpackChunkName: "component---src-pages-open-innovation-ecosystems-index-es-js" */),
  "component---src-pages-open-innovation-index-en-js": () => import("./../../../src/pages/open-innovation/index.en.js" /* webpackChunkName: "component---src-pages-open-innovation-index-en-js" */),
  "component---src-pages-open-innovation-index-es-js": () => import("./../../../src/pages/open-innovation/index.es.js" /* webpackChunkName: "component---src-pages-open-innovation-index-es-js" */),
  "component---src-pages-press-news-index-en-js": () => import("./../../../src/pages/press-news/index.en.js" /* webpackChunkName: "component---src-pages-press-news-index-en-js" */),
  "component---src-pages-press-news-index-es-js": () => import("./../../../src/pages/press-news/index.es.js" /* webpackChunkName: "component---src-pages-press-news-index-es-js" */),
  "component---src-pages-privacy-policy-index-en-js": () => import("./../../../src/pages/privacy-policy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-en-js" */),
  "component---src-pages-privacy-policy-index-es-js": () => import("./../../../src/pages/privacy-policy/index.es.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-es-js" */),
  "component---src-pages-recent-challenges-index-en-js": () => import("./../../../src/pages/recent-challenges/index.en.js" /* webpackChunkName: "component---src-pages-recent-challenges-index-en-js" */),
  "component---src-pages-recent-challenges-index-es-js": () => import("./../../../src/pages/recent-challenges/index.es.js" /* webpackChunkName: "component---src-pages-recent-challenges-index-es-js" */),
  "component---src-pages-search-index-en-js": () => import("./../../../src/pages/search/index.en.js" /* webpackChunkName: "component---src-pages-search-index-en-js" */),
  "component---src-pages-search-index-es-js": () => import("./../../../src/pages/search/index.es.js" /* webpackChunkName: "component---src-pages-search-index-es-js" */),
  "component---src-pages-social-impact-project-index-en-js": () => import("./../../../src/pages/social-impact-project/index.en.js" /* webpackChunkName: "component---src-pages-social-impact-project-index-en-js" */),
  "component---src-pages-social-impact-project-index-es-js": () => import("./../../../src/pages/social-impact-project/index.es.js" /* webpackChunkName: "component---src-pages-social-impact-project-index-es-js" */),
  "component---src-pages-social-solver-ecosystem-index-en-js": () => import("./../../../src/pages/social-solver-ecosystem/index.en.js" /* webpackChunkName: "component---src-pages-social-solver-ecosystem-index-en-js" */),
  "component---src-pages-social-solver-ecosystem-index-es-js": () => import("./../../../src/pages/social-solver-ecosystem/index.es.js" /* webpackChunkName: "component---src-pages-social-solver-ecosystem-index-es-js" */),
  "component---src-pages-startup-calls-index-en-js": () => import("./../../../src/pages/startup-calls/index.en.js" /* webpackChunkName: "component---src-pages-startup-calls-index-en-js" */),
  "component---src-pages-startup-calls-index-es-js": () => import("./../../../src/pages/startup-calls/index.es.js" /* webpackChunkName: "component---src-pages-startup-calls-index-es-js" */),
  "component---src-pages-technology-blog-index-en-js": () => import("./../../../src/pages/technology-blog/index.en.js" /* webpackChunkName: "component---src-pages-technology-blog-index-en-js" */),
  "component---src-pages-technology-blog-index-es-js": () => import("./../../../src/pages/technology-blog/index.es.js" /* webpackChunkName: "component---src-pages-technology-blog-index-es-js" */),
  "component---src-pages-technology-expert-networks-index-en-js": () => import("./../../../src/pages/technology-expert-networks/index.en.js" /* webpackChunkName: "component---src-pages-technology-expert-networks-index-en-js" */),
  "component---src-pages-technology-expert-networks-index-es-js": () => import("./../../../src/pages/technology-expert-networks/index.es.js" /* webpackChunkName: "component---src-pages-technology-expert-networks-index-es-js" */),
  "component---src-pages-terms-and-conditions-index-en-js": () => import("./../../../src/pages/terms-and-conditions/index.en.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-en-js" */),
  "component---src-pages-terms-and-conditions-index-es-js": () => import("./../../../src/pages/terms-and-conditions/index.es.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-es-js" */),
  "component---src-pages-upgrading-rd-downturn-digital-index-en-js": () => import("./../../../src/pages/upgrading-rd-downturn-digital/index.en.js" /* webpackChunkName: "component---src-pages-upgrading-rd-downturn-digital-index-en-js" */),
  "component---src-pages-upgrading-rd-downturn-digital-index-es-js": () => import("./../../../src/pages/upgrading-rd-downturn-digital/index.es.js" /* webpackChunkName: "component---src-pages-upgrading-rd-downturn-digital-index-es-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-challenges-archive-template-js": () => import("./../../../src/templates/ChallengesArchiveTemplate.js" /* webpackChunkName: "component---src-templates-challenges-archive-template-js" */),
  "component---src-templates-challenges-eng-js": () => import("./../../../src/templates/challengesENG.js" /* webpackChunkName: "component---src-templates-challenges-eng-js" */),
  "component---src-templates-challenges-es-js": () => import("./../../../src/templates/challengesES.js" /* webpackChunkName: "component---src-templates-challenges-es-js" */),
  "component---src-templates-leader-ship-template-js": () => import("./../../../src/templates/LeaderShipTemplate.js" /* webpackChunkName: "component---src-templates-leader-ship-template-js" */),
  "component---src-templates-press-and-news-archive-js": () => import("./../../../src/templates/PressAndNewsArchive.js" /* webpackChunkName: "component---src-templates-press-and-news-archive-js" */),
  "component---src-templates-press-news-js": () => import("./../../../src/templates/pressNews.js" /* webpackChunkName: "component---src-templates-press-news-js" */)
}

